import React, { Fragment } from 'preact/compat'
import { h } from 'preact'
import { AddressField } from './address_field'
import { useError } from './landing_page'
import { PhoneFieldWithSms, PhoneField } from './phone_field'

export function LeadCaptureForm({ member = {}, leadCaptureForm }) {
  const { errors } = useError()

  return (
    <>
      {leadCaptureForm.map((field) => (
        <div>
          <label class={`label !p-0 ${(field.key === 'name' || field.required) && 'required'}`}>
            {field.name}
            {field.label}
          </label>
          {renderField(field, member, errors)}
        </div>
      ))}
    </>
  )
}

function renderField(field, member, errors) {
  if (field.key === 'phone') {
    return (
      <PhoneFieldWithSms
        number={member.phone?.number}
        sendSms={member.phone?.send_sms}
        required={field.required}
        errors={errors}
        numberChange={(number) => {
          member.phone ||= {}
          member.phone.number = number
        }}
        smsChange={(sendSms) => {
          member.phone ||= {}
          member.phone.send_sms = sendSms
        }}
      />
    )
  }

  if (field.type === 'tel') {
    return (
      <PhoneField
        number={member.phone?.number}
        required={field.required}
        numberChange={(number) => {
          member[field.key] = number
        }}
      />
    )
  }

  if (field.key === 'address') {
    return (
      <AddressField
        address={member.address || {}}
        required={field.required}
        addressChange={(address) => (member.address = address)}
      />
    )
  }

  if (field.type === 'select') {
    return (
      <select
        type={field.type}
        class="input input-border"
        required={field.required}
        onInput={({ target }) => (member[field.key] = target.value)}
      >
        <option selected disabled hidden>
          Select
        </option>

        {field.options?.map((option) => (
          <option>{option}</option>
        ))}
      </select>
    )
  }

  return (
    <input
      type={field.type}
      class={`input input-border ${field.type === 'date' && 'h-10'}`}
      placeholder="Type here"
      value={member[field.key]}
      required={field.required}
      onInput={({ target }) => (member[field.key] = target.value)}
    />
  )
}
