import React from 'preact/compat'
import { createContext, h } from 'preact'
import { useContext } from 'preact/hooks'
import { signal } from '@preact/signals'
import { SignUp } from './landing_pages/sign_up'
import { Confirmation } from './landing_pages/confirmation'
import { StepTracker } from './landing_pages/step_tracker'

const LandingPageState = createContext({
  step: signal(1),
  numberOfSteps: 3,
  members: signal([{ primary: true }]),
  leadCaptureForm: {},
  errors: signal({}),
})

export function useLeadCaptureForm() {
  const { leadCaptureForm } = useContext(LandingPageState)

  return { leadCaptureForm }
}

export function useStep() {
  const { step, numberOfSteps, error } = useContext(LandingPageState)

  const nextStep = () => {
    if (step.value < numberOfSteps) {
      step.value++
    }
  }

  const jumpToStep = (newStep) => {
    step.value = newStep
  }

  const previousStep = () => {
    if (step.value > 1) {
      step.value--
    }
  }

  return { step: step.value, numberOfSteps, nextStep, previousStep, jumpToStep }
}

export function useMembers() {
  const { members } = useContext(LandingPageState)

  const addMember = () => {
    members.value = [...members.value, { primary: false }]
  }

  return { members: members.value, addMember }
}

export function useError() {
  const { errors } = useContext(LandingPageState)

  const setError = (value) => {
    errors.value = value
  }

  return { errors: errors.value, setError }
}

function LandingPageCore() {
  const { step, numberOfSteps } = useStep()

  return (
    <div class="mt-6">
      <div class="flex item-center gap-4 justify-between mb-4">
        <div class="text-xl font-bold">Sign Up</div>

        <StepTracker />
      </div>

      {step === 1 && <SignUp />}
      {step === numberOfSteps && <Confirmation />}
    </div>
  )
}

export function LandingPage({ leadCaptureForm }) {
  return (
    <LandingPageState.Provider
      value={{
        step: signal(1),
        numberOfSteps: 2,
        members: signal([{ primary: true }]),
        errors: signal({}),
        leadCaptureForm,
      }}
    >
      <LandingPageCore />
    </LandingPageState.Provider>
  )
}
